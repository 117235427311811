function _findHeaderElement(): HTMLElement | undefined {
    const el = document.getElementsByTagName("header");
    return el.length > 0 ? el[0] : undefined;
}

function _findHeaderBannerElement(): HTMLElement | null {
  return document.getElementById("header-banner");
}

function _initializeHeader(clearTimer: (restart: boolean, timeoutCallback?: () => void) => void, el: Element | null): void {
    const headerContainerEl = _findHeaderElement();
    const headerBannerEl = _findHeaderBannerElement();
    const headerTitlePageEls = headerContainerEl?.getElementsByClassName("page");

    if (el && headerContainerEl && headerTitlePageEls?.length === 1) {
        const range = document.createRange();
        const chars = el.textContent!.length;
        const headerTitleEl = headerTitlePageEls[0];
        const textEl = el.childNodes[0];

        range.setStart(textEl, 0);
        range.setEnd(textEl, chars);
        
        const rect = range.getClientRects()[0];
        const headerRect = headerContainerEl.getBoundingClientRect();
        
        if (rect.bottom < headerRect.height) {
            headerTitleEl.textContent = el.textContent;
            headerTitleEl.classList.add('opacity-100');
            clearTimer(true, () => {
                headerTitleEl.classList.remove('opacity-100');
                headerTitleEl.classList.add('opacity-0');
            });
        }
        else if (rect.top > headerRect.height) {
            clearTimer(false);
            headerTitleEl.classList.remove('opacity-100');
            headerTitleEl.classList.add('opacity-0');
        }
    }
    
    if (window.scrollY == 0) {
        headerContainerEl?.classList.remove("scrolled");
        headerBannerEl?.classList.remove("scrolled");
    } else {
        headerContainerEl?.classList.add("scrolled");
        headerBannerEl?.classList.add("scrolled");
    }
}

function _initializeMenu(offset: number) {
    // TODO: Detect scrollTo and run this method when completed
    // https://stackoverflow.com/a/53957448/1391492
    const menuItems = document.querySelectorAll("#main-menu a.menu-item");
    const menuItemCandidates: Array<{ menuItem: Element, element?: HTMLElement }> = [];
    const urlPathName = new URL(window.location.href).pathname;

    for (let i = 0; i < menuItems.length; i++) {
        const menuItem = menuItems[i];
        let href = menuItem.getAttribute("href");

        if (href?.startsWith("/")) {
            href = href.substring(1);
        }

        if (href === null || !href.startsWith("#")) {
            continue;
        } else if (href === "#") {
            menuItemCandidates.push({
                menuItem,
                element: document.documentElement
            });
        } else {
            const element = <HTMLElement>document.querySelector(href);
            menuItemCandidates.push({
                menuItem,
                element
            });
        }
    }

    var candidates = menuItemCandidates.filter(o => {
        if (!o.element) {
            return false;
        }
        
        var docScrollTop = Math.round(document.documentElement.scrollTop);
        var inViewport = docScrollTop + offset >= o.element.offsetTop;

        return inViewport;
    });

    if (candidates.length > 0) {
        for (let i = 0; i < menuItemCandidates.length; i++) {
            _markMenuItemInactive(menuItemCandidates[i].menuItem);
        }

        if (urlPathName === "/") {
            _markMenuItemActive(candidates[candidates.length - 1]?.menuItem);
        }
    }
}

function _maxBy<T>(x: T, y: T, valueSelector: (o: T) => number): T {
    const xValue = valueSelector(x);
    const yValue = valueSelector(y);
    return xValue >= yValue ? x : y;
}

function _markMenuItemActive(menuItem: Element): void {
    menuItem.classList.add("active");
}

function _markMenuItemInactive(menuItem: Element): void {
    menuItem.classList.remove("active");
}

function _isInViewport(element): false | DOMRect {
    if (!element) {
        return false;
    }

    if (1 !== element.nodeType) {
        return false;
    }
  
    const html = document.documentElement;
    const rect = element.getBoundingClientRect();
  
    if (!rect) {
        return false;
    }

    const isInViewport = rect &&
        rect.bottom >= 0 &&
        rect.right >= 0 && 
        rect.left <= html.clientWidth &&
        rect.top <= html.clientHeight;

    if (!isInViewport) {
        return false;
    }

    return rect;
}

/** https://stackoverflow.com/a/12418814/1391492 */
function _inViewport(element: Element): boolean {
    if (!element) {
        return false;
    }

    if (1 !== element.nodeType) {
        return false;
    }
  
    var html = document.documentElement;
    var rect = element.getBoundingClientRect();
  
    if (!rect) {
        return false;
    }

    return !!rect &&
      rect.bottom >= 0 &&
      rect.right >= 0 && 
      rect.left <= html.clientWidth &&
      rect.top <= html.clientHeight;
}

function _onLoad(): void {
    if (window.location.pathname == "/" || window.location.pathname == "/index.html") {
        const urlParams = new URLSearchParams(window.location.search);
        const sent = urlParams.get('sent');

        const errorEl = document.querySelector("#contact [role=alert].error");
        const successEl = document.querySelector("#contact [role=alert].success");

        if (sent === "false") {
            errorEl?.classList.remove("hidden");
            successEl?.classList.add("hidden");
        } else if (sent !== null) {
            errorEl?.classList.add("hidden");
            successEl?.classList.remove("hidden");
        } else {
            errorEl?.classList.add("hidden");
            successEl?.classList.add("hidden");
        }
    }

    const headerContainerEl = _findHeaderElement();
    if (headerContainerEl) {
        const headerContainerHeight = headerContainerEl.offsetHeight;
        const pageTitle = document.querySelector('body > header + h1.page-title');

        let currentHeaderPageTitleTimeoutHandle: number | undefined;

        const resetTimerCallback: (restart: boolean, timeoutCallback?: () => void) => void = (restart, timeoutCallback) => {
            if (currentHeaderPageTitleTimeoutHandle) {
                window.clearTimeout(currentHeaderPageTitleTimeoutHandle);
            }

            if (restart && timeoutCallback) {
                currentHeaderPageTitleTimeoutHandle = window.setTimeout(() => timeoutCallback(), 3000);
            }
        };

        window.addEventListener("scroll", () => _initializeMenu(headerContainerHeight), false);
        window.addEventListener("scroll", () => _initializeHeader(resetTimerCallback, pageTitle), false);

        _initializeMenu(headerContainerHeight);
        _initializeHeader(resetTimerCallback, pageTitle);

        //

        // const menuElements = document.querySelectorAll("#main-menu a.menu-item, #main-menu ul.dropdown-menu");
        // for (let i = 0; i < menuElements.length; i++) {
        //     const menuElement = menuElements[i];
        //     if (menuElement.tagName === "A") {
        //         if (menuElement.nextElementSibling?.tagName === "UL") {
        //             menuElement.addEventListener("click", () => {
        //                 console.error(menuElement.nextElementSibling?.classList);
        //                 const isOpen = menuElement.nextElementSibling?.classList.contains("open");
        //                 if (isOpen) {
        //                     menuElement.nextElementSibling?.classList.remove("open");
        //                 } else {
        //                     menuElement.nextElementSibling?.classList.add("open");
        //                 }
        //             });
        //         }
        //     }
        // }
    }
}

window.addEventListener("load", _onLoad, false);

//////////

/* plain JS slideToggle https://github.com/ericbutler555/plain-js-slidetoggle */

// Plain vanilla JavaScript version of jQuery's slideToggle(), slideUp(), and slideDown() functions.
HTMLElement.prototype.slideToggle = function (duration, callback) {
    if (this.clientHeight === 0) {
      _s(this, duration, callback, true);
    } else {
      _s(this, duration, callback);
    }
  };

  HTMLElement.prototype.slideUp = function (duration, callback) {
    _s(this, duration, callback);
  };
  
  HTMLElement.prototype.slideDown = function (duration, callback) {
    _s(this, duration, callback, true);
  };
  
  function _s(el, duration, callback, isDown) {
    if (typeof duration === "undefined") duration = 400;
    if (typeof isDown === "undefined") isDown = false;
  
    el.style.overflow = "hidden";
    if (isDown) el.style.display = "block";
  
    var elStyles = window.getComputedStyle(el);
  
    var elHeight = parseFloat(elStyles.getPropertyValue("height"));
    var elPaddingTop = parseFloat(elStyles.getPropertyValue("padding-top"));
    var elPaddingBottom = parseFloat(elStyles.getPropertyValue("padding-bottom"));
    var elMarginTop = parseFloat(elStyles.getPropertyValue("margin-top"));
    var elMarginBottom = parseFloat(elStyles.getPropertyValue("margin-bottom"));
  
    var stepHeight = elHeight / duration;
    var stepPaddingTop = elPaddingTop / duration;
    var stepPaddingBottom = elPaddingBottom / duration;
    var stepMarginTop = elMarginTop / duration;
    var stepMarginBottom = elMarginBottom / duration;
  
    var start;
  
    function step(timestamp) {
      if (start === undefined) start = timestamp;
  
      var elapsed = timestamp - start;
  
      if (isDown) {
        el.style.height = stepHeight * elapsed + "px";
        el.style.paddingTop = stepPaddingTop * elapsed + "px";
        el.style.paddingBottom = stepPaddingBottom * elapsed + "px";
        el.style.marginTop = stepMarginTop * elapsed + "px";
        el.style.marginBottom = stepMarginBottom * elapsed + "px";
      } else {
        el.style.height = elHeight - stepHeight * elapsed + "px";
        el.style.paddingTop = elPaddingTop - stepPaddingTop * elapsed + "px";
        el.style.paddingBottom =
          elPaddingBottom - stepPaddingBottom * elapsed + "px";
        el.style.marginTop = elMarginTop - stepMarginTop * elapsed + "px";
        el.style.marginBottom =
          elMarginBottom - stepMarginBottom * elapsed + "px";
      }
  
      if (elapsed >= duration) {
        el.style.height = "";
        el.style.paddingTop = "";
        el.style.paddingBottom = "";
        el.style.marginTop = "";
        el.style.marginBottom = "";
        el.style.overflow = "";
        if (!isDown) el.style.display = "none";
        if (typeof callback === "function") callback();
      } else {
        window.requestAnimationFrame(step);
      }
    }
  
    window.requestAnimationFrame(step);
  }
  
  // JS
  const overlays = document.querySelector(".overlay");
  const body = document.querySelector("body");
  const menuBtn = document.querySelector(".menu-btn");
  const menuItems = document.querySelector(".menu-items");
  
  // Add class to a tag and ul tag if li parent contains sub-menu
  const liElems = document.querySelectorAll(".menu-items li");
  liElems.forEach((elem) => {
    elem.childNodes.forEach(node => {
      if (node.nodeName === "A" && (!node.nextElementSibling || node.nextElementSibling.nodeName !== "UL" || !node.nextElementSibling?.classList.contains("dropdown-menu"))) {
        node.addEventListener("click", (e) => {
          if (menuBtn?.classList.contains("open")) {
            toggle();
          }
        });
      }
    });
    const childrenElems = elem.querySelectorAll(".dropdown-menu");
    if (childrenElems.length > 0) {
      elem.firstElementChild?.classList.add("expand-btn");
      elem.classList.add("dropdown");
    }
  });
  
  function toggle() {
    // disable overflow body
    body?.classList.toggle("overflow");
    // dark background
    overlays?.classList.toggle("overlay--active");
    // add open class
    menuBtn?.classList.toggle("open");
    menuItems?.classList.toggle("open");
  }
  
  // Open Menu Mobile
  menuBtn?.addEventListener("click", (e) => {
    e.stopPropagation();
    toggle();
  });
  
  // Closes when the Esc key is pressed
  window.onkeydown = function (event) {
    const key = event.key; // const {key} = event; in ES6+
    const active = menuItems?.classList.contains("open");
    if (key === "Escape" && active) {
      toggle();
    }
  };
  
  // Closes when clicked outside the area
  document.addEventListener("click", (e) => {
    let target = e.target,
      its_menu = target === menuItems || menuItems?.contains(target),
      its_hamburger = target === menuBtn,
      menu_is_active = menuItems?.classList.contains("open");
    if (!its_menu && !its_hamburger && menu_is_active) {
      toggle();
    }
  });
  
  // Mobile menu expand
  const expandBtn = document.querySelectorAll(".expand-btn");
  expandBtn.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      if (window.innerWidth <= 1024) {
        // Prevent Default Anchor Click Behavior
        event.preventDefault();
        btn.classList.toggle("open");
        btn.nextElementSibling?.slideToggle(300);
      }
    });
  });
  
  window.addEventListener("resize", () => {
    if (window.innerWidth > 1024) {
      // Off menu mobile on desktop
      if (menuBtn?.classList.contains("open")) {
        toggle();
      }
      // Change arrow menu on Desktop
      for (var i = 0; i < expandBtn.length; i += 1) {
        expandBtn[i].classList.remove("open");
      }
      // Off SlideToggle Menu on Desktop
      const dropdownMenu = document.querySelectorAll(
        ".menu-items .dropdown-menu"
      );
      for (var i = 0; i < dropdownMenu.length; i += 1) {
        dropdownMenu[i].style.display = "";
      }
    }
  });
  